* {
  margin: 0;
}

body {
  font-size: 0.875rem;
  line-height: 1.43;
  margin: 0;
  font-family: 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '-apple-system';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
